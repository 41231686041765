
import { defineComponent } from "vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import SaleMonthCalendar from "@/components/sales/SaleMonthCalendar.vue";
import ValidateError from "@/components/ValidateError.vue";
import { PageService } from "@/services/PageService";
import { SaleService } from "@/services/SaleService";
import { indexSaleApi } from "@/api/sales";
import { okOrderSaleApi } from "@/api/sales";
import { noOrderSaleApi } from "@/api/sales";

export default defineComponent({
  name: "SaleMonth",
  components: {
    ErrorMessage,
    ValidateError,
  },
  data: () => ({
    isShow: false,
    message: "",
    validateErrors: {} as any,
    sales: [] as any[],
    selectSaleId: 0 as number,

    selectNoOrderStatus: "" as string,
    selectNoOrderReason: "" as string,

    // okModal: null as any,
    // ngModal: null as any,

    pageService: new PageService(),
    saleService: new SaleService(),
  }),
  mounted: async function () {
    // sales
    this.getNewSales();
    this.isShow = true;

    document.body.className = "index";

    // this.$nextTick(function () {
    //   this.okModal = document.getElementById("okOrderModal");
    //   this.ngModal = document.getElementById("noOrderModal");
    // });
  },
  methods: {
    setSelectSaleId: function (saleId: number) {
      this.selectSaleId = saleId;
    },
    okOrder: async function () {
      this.validateErrors = [];
      this.message = [];
      const result = await okOrderSaleApi(this.selectSaleId);
      if (!result.success) {
        if (result.status === 422) {
          this.validateErrors = result.data;
          console.log(this.validateErrors);
        }
        if (result.message !== "") {
          this.message = result.message;
        }
        return;
      }
      window.location.reload();
      // this.okModal.hide();
      // this.getNewSales();
    },
    noOrder: async function (data: any) {
      this.validateErrors = [];
      this.message = [];
      const result = await noOrderSaleApi(
        this.selectSaleId,
        this.selectNoOrderStatus,
        this.selectNoOrderReason
      );
      if (!result.success) {
        if (result.status === 422) {
          this.validateErrors = result.data;
          console.log(this.validateErrors);
        }
        if (result.message !== "") {
          this.message = result.message;
        }
        return;
      }
      window.location.reload();
      // this.ngModal.hide();
      // this.getNewSales();
    },
    getNewSales: async function () {
      this.sales = [];
      const result = await indexSaleApi({ is_new: 1 });
      if (!result.success) {
        if (result.status === 422) {
          this.validateErrors = result.data;
          console.log(this.validateErrors);
        }
        if (result.message !== "") {
          this.message = result.message;
        }
        return;
      }
      this.sales = result.data;
    },
  },
});
